import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentNavigationLinks extends CmsComponent {
    get allowMediaQuery() {
        return true;
    }

    get DISPLAY_TYPES() {
        return {
            TEXT: `text`,
            TEXT_ICON_LEFT: `text-icon-left`,
            TEXT_ICON_RIGHT: `text-icon-right`,
            TEXT_ICON_TOP: `text-icon-top`,
            ICON: `icon`
        }
    }

    get BUTTON_TYPES() {
        return {
            SOLID: `text`,
            OUTLINE: `outline`,
            LIGHT: `light`,
            TEXT: `text`
        }
    }

    get COMPONENT_TYPES() {
        return {
            DEFAULT: `default`,
            NAV_ITEMS: `buttons`
        }
    }

    type = `navigation-links`

    constructor(data) {
        super(data);

        if (!data) {
            this.links = [];
            this.component = this.COMPONENT_TYPES.DEFAULT;
            this.display = this.DISPLAY_TYPES.TEXT;
            this.mediaQuery.mobile.layout = this.DISPLAY_TYPES.TEXT_ICON_LEFT;
            this.buttonType = this.BUTTON_TYPES.TEXT;
            this.layout = `horizontal`;
            this.mediaQuery.mobile.layout = `vertical`;

            this.useMenuNavigation = false;
            this.spacing = `5px`;
            this.size = `md`;
            this.iconSize = `1x`;
            this.variant = `primary`;
            this.borderRadius = `var(--border-radius)`;

            this.fillSize = false;
            this.linkSizeMode = `auto`;
            this.linkSize = `100px`;

            this.separator = false;
            this.separatorColor = `#777`;
            this.separatorWidth = `2px`;

            this.collapsing = false;
            this.mediaQuery.mobile.collapsing = true;
        } else {
            this.links = [];

            if (!this.linkSize) {
                this.linkSizeMode = this.linkWidthMode;
                this.linkSize = this.linkWidth;
                this.fillSize = this.fillHeight;
            }

            for (let link of data.links) {
                this.links.push(new CmsLink(link));
            }
        }
        this.renameField(`collapsed`, `collapsing`);
    }

    isCollapsing() {
        return this.isVisible() && this.mediaProxy.collapsing === true;
    }
}
