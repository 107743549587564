export default class CmsBaseClass {
    constructor(data) {
        if (this.autoGenerateId) {
            this.id = generateGuid();
        }

        if (data) {
            this.loadSave(data);
        }
        // console.log(`parent ${this.constructor.name}`, this);
    }

    get ignoredFields() {
        return []
    }

    get autoGenerateId() {
        return true
    }

    getNestedValue(fieldPath) {
        if (!fieldPath || typeof fieldPath !== `string`) {
            return;
        }
        if (fieldPath.indexOf(`.`) === -1) {
            return this[fieldPath];
        }

        // Get value from Nested Object xxx.floor.name  [root]?optional.[type].[field]
        const path = fieldPath.split(`.`);
        let value = this;
        for (let parent of path) {
            value = value[parent];
        }
        return value;
    }

    loadSave(data) {
        if (data._id) {
            data.id = data._id;
            delete data._id;
        }
        for (let key in data) {
            this[key] = data[key];
        }
        this.postLoad(data);
    }

    postLoad(data) {

    }

    duplicate() {
        let copy = new this.constructor();
        copy.loadSave(structuredClone(this.toJSON()));
        copy.id = generateGuid();
        return copy;
    }

    toJSON() {
        let data = {};

        for (let key in this) {
            if (this.ignoredFields.indexOf(key) >= 0) {
                continue;
            }
            if (key !== `ignoredFields` && typeof this[key] !== `function`) {
                if (!this[key]) {
                    data[key] = this[key];
                } else if (this[key].constructor === Array) {
                    data[key] = [];
                    for (let item of this[key]) {
                        if (item && typeof item === `object` && typeof item.toJSON === `function`) {
                            data[key].push(item.toJSON());
                        } else {
                            data[key].push(item);
                        }
                    }
                } else if (typeof this[key] === `object` && typeof this[key].toJSON === `function`) {
                    data[key] = this[key].toJSON();
                /*} else if (typeof this[key] === `object`) {
                    data[key] = clone(this[key]);*/
                } else {
                    data[key] = this[key];
                }
            }
        }
        return data;
    }

    delete() {
        if (this.deleted === false) {
            this.beforeDelete();
            this.deleted = true;
        } else {
            console.error(`Already Deleted`);
        }
    }

    beforeDelete() {
        // to override
    }
}
