import CmsLink from "@/helpers/cms/CmsLink.js";
import CmsBlock from "@/helpers/editor/blocks/CmsBlock.js";
import CmsComponent from "@/helpers/editor/components/CmsComponent.js";

export default class CmsComponentImage extends CmsComponent {

    get allowBinding() {
        return true;
    }

    get allowMediaQuery() {
        return true;
    }

    type = `image`

    constructor(data) {
        super(data);

        this.link = new CmsLink(this.link);

        if (!data) {
            this.src = ``;
            this.ratio = `auto`;
            this.objectFit = `contain`;
            this.align = `center`;
            this.borderRadius = `var(--border-radius)`;
            this.maxWidth = ``;
            this.maxHeight = ``;
            this.filter = `none`;
            this.alt = ``;
            this.filters;

            this.background = CmsBlock.BACKGROUNDS.NONE;
            this.backgroundColor = `#000`;
            this.spacing = 0;
            this.quality = `auto`; // which variant to use auto|sm|xs

            // Item Binding
            this.imageField = null;
            this.linkField = null;
        }
        if (this.maxWidth === `none`) {
            this.maxWidth = ``;
            this.maxHeight = ``;
        }
    }

    getCss() {
        let style = {
            objectFit: this.mediaProxy.objectFit,
            borderRadius: this.mediaProxy.borderRadius
        };
        if (this.mediaProxy.maxWidth) {
            style.width = `${this.mediaProxy.maxWidth}px`;
        }
        if (this.mediaProxy.maxHeight) {
            style.height = `${this.mediaProxy.maxHeight}px`;
        }
        if (style.borderRadius === `50%`) {
            style.aspectRatio = `1 / 1`;
            style.height = `auto`;
        } else if (this.mediaProxy.aspectRatio !== `auto`) {
            style.aspectRatio = this.mediaProxy.aspectRatio;
        }

        style.objectFit = this.mediaProxy.objectFit;

        switch (this.align) {
            case `left`:
                style.marginLeft = `0`;
                break;
            case `right`:
                style.marginRight = `0`;
                break;
        }
        switch (this.filter) {
            case `grayscale`:
                style.filter = `grayscale(1)`;
                break;
            case `sepia`:
                style.filter = `sepia(1)`;
                break;
            case `recolor-white`:
                style.filter = `brightness(0) invert(1)`;
                break;
            case `recolor-black`:
                style.filter = `brightness(0)`;
                break;
        }

        return style;
    }

    getContainerCss() {
        let style = {};
        if (this.mediaProxy.background === CmsBlock.BACKGROUNDS.COLOR) {
            style.backgroundColor = this.backgroundColor;
        }

        return style;
    }
}
