import Model, {type} from "../Model.js";

export default class DatedModel extends Model {
    createdAt = {
        [type]: Date,
        required: true,
        default: () => new Date(),
        defaultOnly: true,
        insertOnly: true
    }

    updatedAt ={
        [type]: Date,
        required: true,
        default: () => new Date(),
        defaultOnly: true
    }

    constructor() {
        super(...arguments);
    }
}
