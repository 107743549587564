import store from "@/store.js";

export default class CmsLanguageProxy {
    static createProxy(owner) {
        return new Proxy(owner, {
            get(target, prop) {
                if (prop && typeof target[prop] === `object` && target[prop]) {
                    return target[prop][store.getters.displayLanguage];
                } else {
                    return target[prop];
                }
            },
            set(target, prop, value) {
                if (prop && typeof target[prop] === `object`) {
                    target[prop][store.getters.displayLanguage] = value;
                } else {
                    target[prop] = value;
                }
                return true;
            }
        });
    }
}
