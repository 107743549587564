import AnalyticsSession from "../../common/db/models/analytics/AnalyticsSession.js";

export default class SessionTracker {
    static session;

    static init(globalSessionInfo = {}) {
        if (this.initialized) {
            return;
        }
        this.initialized = true;

        window.SessionTracker = SessionTracker;
        this.retrieveSession(globalSessionInfo);

        document.addEventListener(`pointerup`, this.handleClick.bind(this));
        document.addEventListener(`scrollend`, this.handleScroll.bind(this), {capture: true});
        document.addEventListener(`visibilitychange`, this.handleVisibility.bind(this));

        window.addEventListener(`beforeunload`, event => {
            this.saveSession();
        })
    }

    static clean() {
        document.removeEventListener(`pointerup`, this.handleClick.bind(this));
        document.removeEventListener(`scrollend`, this.handleScroll.bind(this), {capture: true});
        document.removeEventListener(`visibilitychange`, this.handleVisibility.bind(this));
    }

    static async handleClick(event) {
        const element = event.target.nodeName === `SPAN` ? event.target.parentNode : event.target;

        const action = {
            tag: element.nodeName.toLowerCase(),
            tag_id: event.target.id,
            text: event.target.textContent,
            offsetX: event.offsetX,
            offsetY: event.offsetY,
            pageX: event.pageX,
            pageY: event.pageY,
            clientX: event.clientX,
            clientY: event.clientY,
            screenX: event.screenX,
            screenY: event.screenY
        }

        this.addCustomAction(`click`, action);
    }

    static handleScroll(event) {
        const element = event.target.nodeName === `SPAN` ? event.target.parentNode : event.target;

        this.addCustomAction(`scroll`, {
            tag: element.nodeName.toLowerCase(),
            tag_id: event.target.id,
            classes: [...event.target.classList]
        });
    }

    static handleVisibility(event) {
        this.addCustomAction(`visibilitychange`, {
            visible: !document.hidden
        });
    }

    static async createNewSession(globalSessionInfo = {}) {
        this.session = AnalyticsSession.createInUpdateMode({
            userAgent: navigator.userAgent,
            origin: window.location.origin,
            ...globalSessionInfo
        })
    }

    static async retrieveSession(globalSessionInfo = {}) {
        const sessionId = sessionStorage.getItem(`session_id`);
        if (sessionId) {
            this.createNewSession({id: sessionId, ...globalSessionInfo});
        } else {
            this.createNewSession(globalSessionInfo);
        }
    }

    static async saveSession() {
        if (!this.session) {
            return;
        }

        try {
            this.session.language = localStorage.getItem(`language`) || navigator.language.substring(0, 2);
            await this.session.save({refresh: true, baseUrl: window.env.VUE_APP_URLS__SYSTEM_API});
            sessionStorage.setItem(`session_id`, this.session.id);
            // eslint-disable-next-line no-empty
        } catch {
        }
    }

    static addCustomAction(type, data) {
        if (!this.session) {
            return;
        }

        this.session.addAction(type, data);

        this.saveSession()
            .catch(e => console.error(e));
    }
}
