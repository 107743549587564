export default {
    local: {
        VUE_APP_ENV: `local`,
        VUE_APP_URL: `http://localhost:2050`,
        VUE_APP_API_URL: `http://localhost:2050`,
    },
    dev: {
        VUE_APP_ENV: `dev`,
        VUE_APP_URL: `https://dev.widgets.eyein.media`,
        VUE_APP_API_URL: `https://dev.widgets.eyein.media`,
    },
    demo: {
        VUE_APP_ENV: `demo`,
        VUE_APP_URL: `https://widgets.eyein.media`,
        VUE_APP_API_URL: `https://widgets.eyein.media`,
    },
    live: {
        VUE_APP_ENV: `live`,
        VUE_APP_URL: `https://widgets.eyein.media`,
        VUE_APP_API_URL: `https://widgets.eyein.media`,
    }
};
