import DatedModel from "../DatedModel.js";
import {type} from "../../Model.js";

export default class AnalyticsSession extends DatedModel {
    static collection = `analytics_sessions`;
    static endpoint = `/analytics/sessions`;

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true
    };

    type = {
        [type]: String,
        required: true
    };

    actions = {
        [type]: Array,
        required: false,
        default: [],
        pushOnly: true
    };

    dayOfWeek = {
        [type]: String,
        required: true,
        default: () => {
            const dayIndex = new Date().getDay();
            return [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`].at(dayIndex);
        },
        insertOnly: true,
        defaultOnly: true
    };

    addAction(type, data) {
        data.type = type;
        data.date = new Date();
        if (globalThis.location) {
            data.path = globalThis.location.pathname;
        }
        this.actions.push(data);
    }

    constructor() {
        super(...arguments);
    }
}
